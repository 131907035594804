import ResearchFunctionsController from './research_functions_controller'

export default class extends ResearchFunctionsController {
  static targets = ["homecontainer", "formcontainer",
    "tab1", "tab2", "tab3", "tab4", "tab5", "tab6", "tab7", "tab8",
    "content1", "content2", "content3", "content4", "content5", "content6", "content7", "content8",
    "btnNext1", "btnNext2", "btnNext3", "btnNext4", "btnNext5", "btnNext6", "btnNext7",

    "title", "errorTitle",
    "is_member1", "is_member2", "errorIsMember",
    "firstname", "lastname", "errorFirstname", "errorLastname",
    "email", "errorEmail", "formatEmail",
    "prevEmail", "email_check", "email_x", "email_question", "email_load", "email_load_text",
    "phone", "errorPhone",
    "work_environment", "errorWork_environment",
    "other_work_environment_div", "other_work_environment", "errorOther_work_environment",
    "organization", "errorOrganization",
    "street", "zip", "errorStreet", "errorZip",
    "city", "country", "errorCity", "errorCountry",
    "addcoapplicants", "is_app1", "is_app2", "is_app_text_div", "is_app_text",
    "errorIs_App", "errorIs_App_Text",

    "addCoApplicant",
    "coApp_is_member_yes-0", "coApp_is_member_no-0", "coApp_is_involved-0", "errorApplicantTitle-0", "errorApplicantMember-0",
    "addCoApplicantParent", "addCoApplicantName-0", "addCoApplicantOrg-0", "addCoApplicantEmail-0",
    "plusCoApplicant-0", "removeCoApplicant", "plusCoApplicant", "coApplicantTotal",
    "coApplicantTotalText", "errorApplicantName-0", "errorApplicantOrg-0", "errorApplicantEmail-0", "errorApplicantInvolved-0",

    "study_code", "objective", "errorStudy_code", "errorObjective",
    "sponsor", "errorSponsor",
    "intended_use", "errorIntended_use",
    "other_intended_use_div", "other_intended_use", "errorOther_intended_use",
    "primary_use", "errorPrimary_use",
    "other_primary_use_div", "other_primary_use", "errorOther_primary_use",
    "clinical", "errorClinical",
    "other_clinical_div", "other_clinical", "errorOther_clinical",
    "no_of_patients", "errorNo_of_patients",
    "start_date_pickr", "start_date", "errorStart_date",
    "end_date_pickr", "end_date", "errorEnd_date", "errorEnd_dateGreater",
    "duration",

    "study_motivation", "errorStudy_motivation", "wordCount1", "wordCount1Max",

    "project_summary", "errorProject_summary", "wordCount2", "wordCount2Max",

    "instrument_cont", "errorInstrument_cont", "wordCount3", "wordCount3Max",

    "excontainer", "levelLink", "level", "searchBar", "tableHead",
    "addedProducts", "noData", "search_id", "clearBtn",
    "EqHwbL", "EqHwbS", "EqTips", "Eq5dY5l", "headProductIds",
    "except", "hiddenProductIds", "hiddenProducts", "errorHiddenProductIds",

    "is_modify1", "is_modify2", "errorIs_modify",
    "is_modify_text_div", "is_modify_text", "errorIs_modify_text",
    "is_anonymization1", "is_anonymization2", "errorIs_anonymization",
    "is_notifications1", "is_notifications2", "errorIs_notifications",
    "is_agree", "errorIs_generalcondition"]

  onScroll() {
    let c1Top = this.content1Target.getBoundingClientRect().top,
      c2Top = this.content2Target.getBoundingClientRect().top,
      c3Top = this.content3Target.getBoundingClientRect().top,
      c4Top = this.content4Target.getBoundingClientRect().top,
      c5Top = this.content5Target.getBoundingClientRect().top,
      c6Top = this.content6Target.getBoundingClientRect().top,
      c7Top = this.content7Target.getBoundingClientRect().top,
      c8Top = this.content8Target.getBoundingClientRect().top

    if ((c1Top >= 1 && c1Top <= 105) || (c1Top >= -95 && c1Top < 0)) {
      this.tab1Target.classList.add("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c2Top >= 1 && c2Top <= 105) || (c2Top >= -95 && c2Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.add("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c3Top >= 1 && c3Top <= 105) || (c3Top >= -95 && c3Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.add("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c4Top >= 1 && c4Top <= 105) || (c4Top >= -95 && c4Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.add("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c5Top >= 1 && c5Top <= 105) || (c5Top >= -95 && c5Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.add("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c6Top >= 1 && c6Top <= 105) || (c6Top >= -95 && c6Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.add("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c7Top >= 1 && c7Top <= 105) || (c7Top >= -95 && c7Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.add("active-experiment")
      this.tab8Target.classList.remove("active-experiment")
    } else if ((c8Top >= 1 && c8Top <= 105) || (c8Top >= -95 && c8Top < 0)) {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.add("active-experiment")
    }
  }

  understand() {
    this.homecontainerTarget.classList.add("d-none")
    this.formcontainerTarget.classList.remove("d-none")
    super.scroll2Content(0)
  }

  tab1() {
    this.tab1Target.classList.add("active-experiment")
    this.tab2Target.classList.remove("active-experiment")
    this.tab3Target.classList.remove("active-experiment")
    this.tab4Target.classList.remove("active-experiment")
    this.tab5Target.classList.remove("active-experiment")
    this.tab6Target.classList.remove("active-experiment")
    this.tab7Target.classList.remove("active-experiment")
    this.tab8Target.classList.remove("active-experiment")
    super.scroll2Content(super.findPosition(this.content1Target))
  }
  tab2() {
    if (this.content1Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.add("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab2Target.classList.remove("fade-experiment")
      this.content2Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content2Target))
    }
  }
  tab3() {
    if (this.content2Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.add("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab3Target.classList.remove("fade-experiment")
      this.content3Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content3Target))
    }
  }
  tab4() {
    if (this.content3Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.add("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab4Target.classList.remove("fade-experiment")
      this.content4Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content4Target))
    }
  }
  tab5() {
    if (this.content4Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.add("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab5Target.classList.remove("fade-experiment")
      this.content5Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content5Target))
    }
  }
  tab6() {
    if (this.content5Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.add("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab6Target.classList.remove("fade-experiment")
      this.content6Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content6Target))
    }
  }
  tab7() {
    if (this.content6Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.add("active-experiment")
      this.tab8Target.classList.remove("active-experiment")

      this.tab7Target.classList.remove("fade-experiment")
      this.content7Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content7Target))
    }
  }
  tab8() {
    if (this.content6Target.getAttribute('data-value') != 'red') {
      this.tab1Target.classList.remove("active-experiment")
      this.tab2Target.classList.remove("active-experiment")
      this.tab3Target.classList.remove("active-experiment")
      this.tab4Target.classList.remove("active-experiment")
      this.tab5Target.classList.remove("active-experiment")
      this.tab6Target.classList.remove("active-experiment")
      this.tab7Target.classList.remove("active-experiment")
      this.tab8Target.classList.add("active-experiment")

      this.tab8Target.classList.remove("fade-experiment")
      this.content8Target.classList.remove("d-none")
      super.scroll2Content(super.findPosition(this.content8Target))
    }
  }
  // first tab your details
  changeEmail(e) {
    let url = '/verify_email'
    let email = this.emailTarget
    if (email.value != '') {
      if (super.emailIsValid(email.value)) {
        if (this.prevEmailTarget.value != email.value) {
          this.email_checkTarget.classList.add("d-none")
          this.email_xTarget.classList.add("d-none")
          this.email_questionTarget.classList.add("d-none")
          this.email_loadTarget.classList.remove("d-none")
          this.email_load_textTarget.classList.remove("d-none")
          this.errorEmailTarget.classList.add("d-none")
          this.formatEmailTarget.classList.add("d-none")
          const params = { email: email.value }
          super.getEmail(url, params)
            .then(data => {
              super.verifyEmail(data, email, this.prevEmailTarget, this.email_checkTarget, this.email_xTarget, this.email_questionTarget, this.email_loadTarget, this.email_load_textTarget)
            });
        }
      }
    }
  }
  changePhone(e) { }
  changeWorkEnvironment(e) {
    if (this.work_environmentTarget.value == 'Other') {
      this.other_work_environment_divTarget.classList.remove('d-none')
      this.other_work_environmentTarget.disabled = false
    } else {
      this.other_work_environment_divTarget.classList.add('d-none')
      this.other_work_environmentTarget.disabled = true
    }
  }
  changeAddCoapplicant(e) {
    if (this.addcoapplicantsTarget.checked) {
      this.tab2Target.classList.remove("d-none")
      this.btnNext1Target.classList.remove('d-none')
    } else {
      this.tab2Target.classList.add("d-none")
      this.content2Target.classList.add("d-none")
      if (this.content3Target.getAttribute('data-value') == 'red') {
        this.btnNext1Target.classList.remove('d-none')
      }
    }
  }
  clickNext1(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.titleTarget, this.errorTitleTarget)
    isValid ? isValid = super.checkRadioBlank(isValid, this.is_member1Target, this.is_member2Target, this.errorIsMemberTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.firstnameTarget, this.errorFirstnameTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.lastnameTarget, this.errorLastnameTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.emailTarget, this.errorEmailTarget) ? super.checkEmail(this.emailTarget, this.formatEmailTarget) : false : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.phoneTarget, this.errorPhoneTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.work_environmentTarget, this.errorWork_environmentTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.other_work_environmentTarget, this.errorOther_work_environmentTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.organizationTarget, this.errorOrganizationTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.streetTarget, this.errorStreetTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.zipTarget, this.errorZipTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.cityTarget, this.errorCityTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.countryTarget, this.errorCountryTarget) : isValid = false
    if (isValid) {
      if (this.addcoapplicantsTarget.checked) {
        this.content1Target.setAttribute('data-value', 'green')
        this.tab2()
      } else {
        this.content2Target.setAttribute('data-value', 'green')
        this.tab3()
      }
      this.btnNext1Target.classList.add('d-none')
    }
  }

  // second tab co-applicants
  addCoApplicantDiv(e) {
    let get_id = e.currentTarget.getAttribute('data-research-target')
    let result = get_id.split('-')
    let count = parseInt(result[1]) + 1
    let div = this.addCoApplicantTarget
    let htmlString = `
    <div class="addVendorParent" data-research-target="addedDivCoApplicant${count}"  data-number="${count}">
    <hr class="hrDashed mt-3 mb-2">
    <div class="d-flex justify-content-between mb-2">
      <div class="col">
        <small>
          <label for="title" class="form-label label-ex">Title / Salutation <span class="required-field"></span></label>
        </small>
        <select data-research-target="coApplicantTitle-${count}" name="coApplicantTitle[]" class="form-control">
          <option value="Dr.">Dr.</option>
          <option value="Mrs.">Mrs.</option>
          <option value="Ms.">Ms.</option>
          <option value="Mr.">Mr.</option>
          <option value="Other">Other</option>
        </select>
        <span class="text-danger d-none" data-research-target="errorApplicantTitle-${count}"><small>Please complete this field</small></span>
      </div>
      <div class="col">
        <small>
          <label for="is_member" class="form-label label-ex">Member of EuroQol Group? <span class="required-field"></span></label>
        </small>
        <div class="row">
          <div class="roundBoxCoapplicant form-check">
            <input type="radio" data-research-target="coApp_is_member-${count}" id="is_member_yes-${count}" value="yes" name="is_member-${count}">
            <label for="is_member_yes-${count}" class="form-check-label label-ex text-left"><small>Yes</small></label>
          </div>
          <div class="roundBoxCoapplicant form-check">
            <input type="radio" data-research-target="coApp_is_member-${count}" id="is_member_no-${count}" value="no" name="is_member-${count}">
            <label for="is_member_no-${count}" class="form-check-label label-ex text-left"><small>No</small></label>
          </div>
        </div>
        <span class="text-danger d-none" data-research-target="errorApplicantMember-${count}"><small>Please select an option</small></span>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="col">
        <small><label class="form-label label-ex text-left">Fullname</label></small>
        <input type="text" data-research-target="addCoApplicantName-${count}" class="form-control addVendorEmail" name="CoApplicantName[]">
        <span class="text-danger d-none" data-research-target="errorApplicantName-${count}"><small>Please complete this field</small></span>
      </div>
      <div class="col">
        <small>
          <label for="is_involved" class="form-label label-ex">Co-applicant and Organization involved in administration/distribution? <span class="required-field"></span></label>
        </small>
        <div class="row">
          <div class="roundBoxCoapplicant form-check">
            <input type="radio" data-research-target="coApp_is_involved-${count}" id="is_involved_yes-${count}" value="yes" name="is_involved-${count}">
            <label for="is_involved_yes-${count}" class="form-check-label label-ex text-left"><small>Yes</small></label>
          </div>
          <div class="roundBoxCoapplicant form-check">
            <input type="radio" data-research-target="coApp_is_involved-${count}" id="is_involved_no-${count}" value="no" name="is_involved-${count}">
            <label for="is_involved_no-${count}" class="form-check-label label-ex text-left"><small>No</small></label>
          </div>
        </div>
        <span class="text-danger d-none" data-research-target="errorApplicantInvolved-${count}"><small>Please select an option</small></span>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="col">
        <small><label class="form-label label-ex text-left">Email</label></small>
        <input type="text" data-research-target="addCoApplicantEmail-${count}" class="form-control addVendorPhone" name="CoApplicantEmail[]">
        <span class="text-danger d-none" data-research-target="errorApplicantEmail-${count}"><small>Please complete this field</small></span>
      </div>
      <div class="col">
        <small><label class="form-label label-ex text-left">Organization</label></small>
        <input type="text" data-research-target="addCoApplicantOrg-${count}" class="form-control addVendorPhone" name="CoApplicantOrg[]">
        <span class="text-danger d-none" data-research-target="errorApplicantOrg-${count}"><small>Please complete this field</small></span>
      </div>
    </div>
    <div class="col-12">
      <span class="removeVendor badge" data-research-target="removeCoApplicant-${count}" data-action="click->research#removeCoApplicantDiv">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-danger mb-2" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        Remove Co-applicant
      </span>
      <span class="addVendor badge" data-research-target="plusCoApplicant-${count}" data-action="click->research#addCoApplicantDiv">
        <svg xmlns="http://www.w3.org/2000/svg" class="eqText-success mb-2" height="20" fill="none" viewBox="-2.5 -2.5 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        Add more Co-applicant
      </span>
    </div>
  </div>
    `;
    div.insertAdjacentHTML('beforeend', htmlString);

    const remove_btn = this.targets.find('removeCoApplicant-' + parseInt(result[1]) + '')
    const remove_btn2 = this.targets.find('plusCoApplicant-' + parseInt(result[1]) + '')
    if (remove_btn) {
      remove_btn.classList.add('d-none')
    }
    remove_btn2.classList.add('d-none')
  }

  removeCoApplicantDiv(e) {
    this.removeDivCoApplicant(e, 'removeCoApplicant-', 'plusCoApplicant-', 'addedDivCoApplicant')
  }

  removeDivCoApplicant(e, removeId, addId, addDivId) {
    let get_id = e.currentTarget.getAttribute('data-research-target')
    const result = get_id.split('-')
    let count = parseInt(result[1]) - 1
    const remove_div = this.targets.find('' + addDivId + result[1] + '')
    const remove_btn = this.targets.find('' + removeId + count + '')
    const show_btn = this.targets.find('' + addId + count + '')

    if (remove_btn) {
      remove_btn.classList.remove('d-none')
    }
    show_btn.classList.remove('d-none')
    remove_div.remove()
  }

  updateCoApplicant(e) {
    let textCoApplicant = this.coApplicantTotalTextTarget
    let textCoApplicantValue = ''

    let divs = document.querySelectorAll('.addVendorParent')
    for (let i = 0; i < divs.length; i++) {
      let closestCoApplicantTitle = this.targets.find('coApplicantTitle-' + i).value.trim()
      let closestCoApplicantName = this.targets.find('addCoApplicantName-' + i).value.trim()
      let closestCoApplicantEmail = this.targets.find('addCoApplicantEmail-' + i).value.trim()
      let closestCoApplicantOrg = this.targets.find('addCoApplicantOrg-' + i).value.trim()
      let member = this.targets.findAll('coApp_is_member-' + i).filter(checkbox => checkbox.checked)[0].value
      let involved = this.targets.findAll('coApp_is_involved-' + i).filter(checkbox => checkbox.checked)[0].value
      textCoApplicantValue += `${member} ${closestCoApplicantTitle} ${closestCoApplicantName} ${closestCoApplicantOrg} ${closestCoApplicantEmail} ${involved}\n`
    }

    textCoApplicant.value = textCoApplicantValue.trim()
  }

  clickNext2(e) {
    let isValid = true
    let divs = document.querySelectorAll('.addVendorParent')
    for (let i = 0; i < divs.length; i++) {
      let closestCoApplicantTitle = this.targets.find('coApplicantTitle-' + i)
      let closestCoApplicantName = this.targets.find('addCoApplicantName-' + i)
      let closestCoApplicantEmail = this.targets.find('addCoApplicantEmail-' + i)
      let closestCoApplicantOrg = this.targets.find('addCoApplicantOrg-' + i)
      let member = this.targets.findAll('coApp_is_member-' + i).filter(checkbox => checkbox.checked)
      let involved = this.targets.findAll('coApp_is_involved-' + i).filter(checkbox => checkbox.checked)
      let closestCoApplicantTitleErr = this.targets.find('errorApplicantTitle-' + i)
      let closestCoApplicantNameErr = this.targets.find('errorApplicantName-' + i)
      let closestCoApplicantEmailErr = this.targets.find('errorApplicantEmail-' + i)
      let closestCoApplicantOrgErr = this.targets.find('errorApplicantOrg-' + i)
      let closestCoApplicantMemberErr = this.targets.find('errorApplicantMember-' + i)
      let closestCoApplicantInvolvedErr = this.targets.find('errorApplicantInvolved-' + i)

      if (member.length > 0) {
        closestCoApplicantMemberErr.classList.add("d-none")
      } else {
        closestCoApplicantMemberErr.classList.remove("d-none")
        isValid = false
      }
      if (involved.length > 0) {
        closestCoApplicantInvolvedErr.classList.add("d-none")
      } else {
        closestCoApplicantInvolvedErr.classList.remove("d-none")
        isValid = false
      }

      isValid ? isValid = super.checkBlank(isValid, closestCoApplicantTitle, closestCoApplicantTitleErr) : isValid = false
      isValid ? isValid = super.checkBlank(isValid, closestCoApplicantName, closestCoApplicantNameErr) : isValid = false
      isValid ? isValid = super.checkBlank(isValid, closestCoApplicantEmail, closestCoApplicantEmailErr) : isValid = false
      isValid ? isValid = super.checkBlank(isValid, closestCoApplicantOrg, closestCoApplicantOrgErr) : isValid = false
    }
    if (isValid) {
      this.updateCoApplicant()
      this.content2Target.setAttribute('data-value', 'green')
      this.tab3()
      this.btnNext2Target.classList.add('d-none')
    }
  }

  // third tab Intended use of Experimental instrument
  changeIntendedUse(e) {
    if (this.intended_useTarget.value == 'Other') {
      this.other_intended_use_divTarget.classList.remove('d-none')
      this.other_intended_useTarget.disabled = false
    } else {
      this.other_intended_use_divTarget.classList.add('d-none')
      this.other_intended_useTarget.disabled = true
    }
  }
  changePrimaryUse(e) {
    if (this.primary_useTarget.value == 'Other') {
      this.other_primary_use_divTarget.classList.remove('d-none')
      this.other_primary_useTarget.disabled = false
    } else {
      this.other_primary_use_divTarget.classList.add('d-none')
      this.other_primary_useTarget.disabled = true
    }
  }
  changeClinical(e) {
    if (this.clinicalTarget.value == 'Other / Unknown') {
      this.other_clinical_divTarget.classList.remove('d-none')
      this.other_clinicalTarget.disabled = false
    } else {
      this.other_clinical_divTarget.classList.add('d-none')
      this.other_clinicalTarget.disabled = true
    }
  }
  changeStartDate(e) {
    if (this.start_date_pickrTarget.value.trim() != "") {
      this.start_dateTarget.value = super.getDateFormat(this.start_date_pickrTarget.value)
      super.computeDuration(this.start_date_pickrTarget, this.end_date_pickrTarget, this.durationTarget)
    }
  }
  changeEndDate(e) {
    if (this.end_date_pickrTarget.value.trim() != "") {
      this.end_dateTarget.value = super.getDateFormat(this.end_date_pickrTarget.value)
      super.computeDuration(this.start_date_pickrTarget, this.end_date_pickrTarget, this.durationTarget)
    }
  }
  changeIsAppText1(e) {
    this.is_app_text_divTarget.classList.remove('d-none')
    this.is_app_textTarget.disabled = false
  }
  changeIsAppText2(e) {
    this.is_app_text_divTarget.classList.add('d-none')
    this.is_app_textTarget.disabled = true
  }
  clickNext3(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.study_codeTarget, this.errorStudy_codeTarget)
    isValid ? isValid = super.checkBlank(isValid, this.objectiveTarget, this.errorObjectiveTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.sponsorTarget, this.errorSponsorTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.intended_useTarget, this.errorIntended_useTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.other_intended_useTarget, this.errorOther_intended_useTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.primary_useTarget, this.errorPrimary_useTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.other_primary_useTarget, this.errorOther_primary_useTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.clinicalTarget, this.errorClinicalTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.other_clinicalTarget, this.errorOther_clinicalTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.no_of_patientsTarget, this.errorNo_of_patientsTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.start_dateTarget, this.errorStart_dateTarget) : isValid = false
    isValid ? isValid = super.checkBlank(isValid, this.end_dateTarget, this.errorEnd_dateTarget) : isValid = false
    isValid ? isValid = super.checkStartDateIsGreater(isValid, this.start_dateTarget, this.end_dateTarget, this.errorEnd_dateGreaterTarget) : isValid = false
    isValid ? isValid = super.checkRadioBlank(isValid, this.is_app1Target, this.is_app2Target, this.errorIs_AppTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.is_app_textTarget, this.errorIs_App_TextTarget) : isValid = false
    if (isValid) {
      this.content3Target.setAttribute('data-value', 'green')
      this.tab4()
      this.btnNext3Target.classList.add('d-none')
    }
  }

  // fourth tab Study motivation
  stud_mot_countWords(e) {
    super.mot_countWords(this.study_motivationTarget, this.wordCount1Target, this.wordCount1MaxTarget)
  }
  clickNext4(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.study_motivationTarget, this.errorStudy_motivationTarget)
    if (isValid) {
      this.content4Target.setAttribute('data-value', 'green')
      this.tab5()
      this.btnNext4Target.classList.add('d-none')
    }
  }

  // fifth tab Summary of the project
  summary_countWords(e) {
    super.sum_countWords(this.project_summaryTarget, this.wordCount2Target, this.wordCount2MaxTarget)
  }
  clickNext5(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.project_summaryTarget, this.errorProject_summaryTarget)
    if (isValid) {
      this.content5Target.setAttribute('data-value', 'green')
      this.tab6()
      this.btnNext5Target.classList.add('d-none')
    }
  }

  // sixth tab Instrument Contribution
  inst_cont_countWords(e) {
    super.inst_cont_countWords(this.instrument_contTarget, this.wordCount3Target, this.wordCount3MaxTarget)
  }
  clickNext6(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.instrument_contTarget, this.errorInstrument_contTarget)
    if (isValid) {
      this.content6Target.setAttribute('data-value', 'green')
      this.tab7()
      this.btnNext6Target.classList.add('d-none')
    }
  }

  // seventh Select Experimental instrument
  clickEqHwbL() {
    this.loadProducts()
  }
  clickEqHwbS() {
    this.loadProducts()
  }
  clickEqTips() {
    this.loadProducts()
  }
  clickEq5dY5l() {
    this.loadProducts()
  }
  loadProducts() {
    let levelArray = []
    if (this.EqHwbLTarget.checked) {
      levelArray.push('EQ-HWB - long')
      levelArray.push('EQ-HWB')
    } else {
      let index = levelArray.indexOf('EQ-HWB - long');
      if (index > -1) {
        levelArray.splice(index, 1);
      }
      let index2 = levelArray.indexOf('EQ-HWB');
      if (index2 > -1) {
        levelArray.splice(index2, 1);
      }
    }
    if (this.EqHwbSTarget.checked) {
      levelArray.push('EQ-HWB - short')
      levelArray.push('EQ-HWB-S')
    } else {
      let index = levelArray.indexOf('EQ-HWB - short');
      if (index > -1) {
        levelArray.splice(index, 1);
      }
      let index2 = levelArray.indexOf('EQ-HWB-S');
      if (index2 > -1) {
        levelArray.splice(index2, 1);
      }
    }
    if (this.EqTipsTarget.checked) {
      levelArray.push('EQ-TIPS')
    } else {
      let index = levelArray.indexOf('EQ-TIPS');
      if (index > -1) {
        levelArray.splice(index, 1);
      }
    }
    if (this.Eq5dY5lTarget.checked) {
      levelArray.push('EQ-5D-Y-5L')
    } else {
      let index = levelArray.indexOf('EQ-5D-Y-5L');
      if (index > -1) {
        levelArray.splice(index, 1);
      }
    }
    this.levelTarget.value = JSON.stringify(levelArray)
    let link = `/research-and-development?level=${encodeURI(this.levelTarget.value)}`
    JSON.parse(this.exceptTarget.value).length == 0 ? link = link : link = link + `&except=${encodeURI(this.exceptTarget.value)}`
    this.search_idTarget.value.trim() == '' ? link = link : link = link + `&search=${encodeURI(this.search_idTarget.value.trim())}`
    this.levelLinkTarget.href = link
    this.levelLinkTarget.click()
  }
  loadClass() {
    let levelArray = JSON.parse(this.levelTarget.value)
    if (levelArray.length == 0) {
      this.searchBarTarget.classList.add('d-none')
      this.tableHeadTarget.classList.add('d-none')
    } else {
      this.searchBarTarget.classList.remove('d-none')
      this.tableHeadTarget.classList.remove('d-none')
    }
    if (this.excontainerTarget.children.length < 1) {
      this.noDataTarget.classList.remove('d-none')
      this.errorHiddenProductIdsTarget.classList.add('d-none')
    } else {
      this.noDataTarget.classList.add('d-none')
    }
  }
  append_clear(event) {
    (event.currentTarget.value == "") ? this.clearBtnTarget.style.display = "none" : this.clearBtnTarget.style.display = "block"
    this.clearBtnTarget.href = `/research-and-development?except=${encodeURI(this.exceptTarget.value)}`
  }
  input_clear() {
    this.search_idTarget.value = ''
  }
  addProduct1(e) {
    let html_prod = `<div class="row divDashed p-1" data-productid="${e.target.closest('.row').getAttribute('data-productid')}"
                    data-productname="${e.target.closest('.row').getAttribute('data-productname')}" 
                    data-id="${e.target.closest('.row').getAttribute('data-id')}" 
                    data-level="${e.target.closest('.row').getAttribute('data-level')}" 
                    data-country="${e.target.closest('.row').getAttribute('data-country')}" 
                    data-language="${e.target.closest('.row').getAttribute('data-language')}" 
                    data-moa="${e.target.closest('.row').getAttribute('data-moa')}" 
                    data-platform="${e.target.closest('.row').getAttribute('data-platform')}" 
                    data-class="${e.target.closest('.row').getAttribute('data-class')}">
                    <div class="col-sm-2 col-md-1 col-lg-1"><input class="form-check-input" type="checkbox" value="" checked data-action="click->research#removeProduct"></div>
                    <div class="col-sm-19 col-md-11 col-lg-11" data-productid="${e.target.closest('.row').getAttribute('data-productid')}">${e.target.closest('.row').getAttribute('data-productname')}</div></div>`
    this.addedProductsTarget.insertAdjacentHTML('beforeend', html_prod)
    e.target.closest('.row').remove()

    let exceptArray = JSON.parse(this.exceptTarget.value)
    exceptArray.push(e.target.closest('.row').getAttribute('data-productid'))
    this.exceptTarget.value = JSON.stringify(exceptArray)
    super.getProductsAndIds(this.addedProductsTarget.children, this.hiddenProductIdsTarget, this.hiddenProductsTarget)
    if (this.hiddenProductIdsTarget.value.trim() != '') {
      this.headProductIdsTarget.classList.remove('d-none')
      this.errorHiddenProductIdsTarget.classList.add('d-none')
    }
  }
  removeProduct(e) {
    let product_id = e.target.closest('.row').getAttribute('data-productid')
    let html_prod = `<turbo-frame id="endpoint_product_${e.target.closest('.row').getAttribute('data-id')}">
                      <div class="row divDashed p-1 ${e.target.closest('.row').getAttribute('data-class')} bg-clean" data-productid="${e.target.closest('.row').getAttribute('data-productid')}"
                        data-productname="${e.target.closest('.row').getAttribute('data-productname')}" 
                        data-id="${e.target.closest('.row').getAttribute('data-id')}" 
                        data-level="${e.target.closest('.row').getAttribute('data-level')}" 
                        data-country="${e.target.closest('.row').getAttribute('data-country')}" 
                        data-language="${e.target.closest('.row').getAttribute('data-language')}" 
                        data-moa="${e.target.closest('.row').getAttribute('data-moa')}" 
                        data-platform="${e.target.closest('.row').getAttribute('data-platform')}" 
                        data-class="${e.target.closest('.row').getAttribute('data-class')}">
                        <div class="col">${e.target.closest('.row').getAttribute('data-level')}</div>
                        <div class="col">${e.target.closest('.row').getAttribute('data-country')}</div>
                        <div class="col">${e.target.closest('.row').getAttribute('data-language')}</div>
                        <div class="col">${e.target.closest('.row').getAttribute('data-moa')}</div>
                        <div class="col">${e.target.closest('.row').getAttribute('data-platform')}</div>
                        <div class="col mt-2">
                            <button type="button" class="btn badge rounded-pill bg-light text-dark" data-action="click->research#addProduct1">
                              <small>+ Select</small>
                            </button>
                        </div>
                      </div>
                    </turbo-frame>`
    this.excontainerTarget.insertAdjacentHTML('beforeend', html_prod)
    e.target.closest('.row').remove()

    this.clickEqHwbL()
    this.clickEqHwbS()
    this.clickEqTips()

    let exceptArray = JSON.parse(this.exceptTarget.value)
    exceptArray = exceptArray.filter(e => e != product_id)
    this.exceptTarget.value = JSON.stringify(exceptArray)
    super.getProductsAndIds(this.addedProductsTarget.children, this.hiddenProductIdsTarget, this.hiddenProductsTarget)
    if (this.hiddenProductIdsTarget.value.trim() == '') {
      this.headProductIdsTarget.classList.add('d-none')
      this.errorHiddenProductIdsTarget.classList.remove('d-none')
    }
  }
  clickNext7(e) {
    let isValid = true
    isValid = super.checkBlank(isValid, this.hiddenProductIdsTarget, this.errorHiddenProductIdsTarget)
    if (isValid) {
      this.content7Target.setAttribute('data-value', 'green')
      this.tab8()
      this.btnNext7Target.classList.add('d-none')
    }
  }

  // eight tab Permission
  changeIsModify1(e) {
    this.is_modify_text_divTarget.classList.remove('d-none')
    this.is_modify_textTarget.disabled = false
  }
  changeIsModify2(e) {
    this.is_modify_text_divTarget.classList.add('d-none')
    this.is_modify_textTarget.disabled = true
  }
  clickTerms(e) {
    let isValid = true
    isValid ? isValid = super.checkRadioBlank(isValid, this.is_modify1Target, this.is_modify2Target, this.errorIs_modifyTarget) : isValid = false
    isValid ? isValid = super.checkDisabledBlank(isValid, this.is_modify_textTarget, this.errorIs_modify_textTarget) : isValid = false
    isValid ? isValid = super.checkRadioBlank(isValid, this.is_anonymization1Target, this.is_anonymization2Target, this.errorIs_anonymizationTarget) : isValid = false
    isValid ? isValid = super.checkRadioBlank(isValid, this.is_notifications1Target, this.is_notifications2Target, this.errorIs_notificationsTarget) : isValid = false
    if (!isValid) {
      this.is_agreeTarget.checked = false
    }
    if (this.is_agreeTarget.checked) {
      this.errorIs_generalconditionTarget.classList.add('d-none')
    } else {
      this.errorIs_generalconditionTarget.classList.remove('d-none')
    }
  }
}
